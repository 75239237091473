.ratingBoxContainer {
  display: flex;
  align-items: center;

  .ratingBox {
    height: 21px;
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 4px;
    justify-content: space-between;

    p {
      margin: 0;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
    }
    .updatedNoOfRating {
      color: #000000;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .updatedRatingBox {
    background-color: rgba(242, 242, 242, 1) !important;
    border-right: 1px solid rgba(202, 202, 202, 1);
    padding-right: 10px;
    border-radius: 0px;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    margin-left: 6px;
  }
}
