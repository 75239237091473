.logo1 {
  width: 35px;
  animation: logo-1 1s infinite ease-in-out;
}
@keyframes logo-1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.logo2 {
  width: 35px;
  animation: loader-3 1.2s infinite ease-in-out;
}

.loader3 {
  background-color: var(--loader-color, '#E2E8F8');
  animation: loader-3 1.2s infinite ease-in-out;
}

@keyframes loader-3 {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.loader5 {
  border-radius: 50%;
  position: relative;
  &:after,
  &:before {
    position: absolute;
    content: '';
  }
  &:after {
    height: 2px;
    width: 35px;
    background-color: var(--loader-color, '#E2E8F8');
    border-radius: 0.1em;
    bottom: 0;
    left: 0;
    transform-origin: bottom center;
    animation: loader-5-1 0.8s ease-in-out infinite alternate;
  }
  &:before {
    height: 0.2em;
    width: 0.2em;
    background-color: var(--loader-color, '#E2E8F8');
    border-radius: 50%;
    top: 0;
    left: calc(50% - 0.1em);
    animation: loader-5-2 0.4s ease-in-out infinite alternate;
  }
}

@keyframes loader-5-2 {
  0% {
    height: 0.24em;
    transform: translateY(0px);
  }
  75% {
    height: 0.2em;
    width: 0.2em;
  }
  100% {
    height: 0.1em;
    width: 0.24em;
    transform: translateY(0.8em);
  }
}

@keyframes loader-5-1 {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
